import * as React from 'react';
import './NotFound.scss';


export const NotFoundPage: React.FC = () => {
    return(
        <div className='not-found-page'>
            <div className="text">Page Not Found</div>
        </div>
    );
};
