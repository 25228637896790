import './DailyVideoPage.scss';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {ApplicationContext} from "../../components/Shared/Shared";
import {useHistory} from "react-router-dom";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApiVimeoVideoDetails} from "journey-shared/journey/ApiTypes";
import * as Sentry from "@sentry/react";
import {extractErrorMessage} from "../../common/utils";
import {NavigationBar} from "../../components/NavigationBar/NavigationBar";
import {DailyVideo} from "../../components/DailyVideo/DailyVideo";

export const DailyVideoPage: React.FC = () => {

    const {currentUser, showGlobalError, isFetchingCurrentUser, showGlobalWarning} = useContext(ApplicationContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dailyVideoInfo, setDailyVideoInfo] = useState<ApiVimeoVideoDetails | null>(null);
    const history = useHistory();

    useEffect(() => {
        JourneyApiClient.getDailyVideoInfo()
        .then(response => setDailyVideoInfo(response as ApiVimeoVideoDetails))
        .catch((error) => {
            if (error.response?.status === 401) {
                showGlobalWarning("Please Sign In");
            } else {
                Sentry.captureException(error);
                showGlobalError(extractErrorMessage(error));
            }
        })
        .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='daily-video-page'>

            <NavigationBar />

            {
                (function(){
                    if(!isFetchingCurrentUser && !currentUser) {
                        setTimeout(() => history.push("/login"), 1000);
                        return <PleaseWait />;
                    } else if (isLoading) {
                        return <PleaseWait />;
                    } else if (!isLoading && dailyVideoInfo) {
                        return <DailyVideo dailyVideo={dailyVideoInfo} />;
                    } else {
                        return <div>Something went wrong.</div>;
                    }
                })()
            }
        </div>
    );
};