import './OnboardingForm.scss';
import * as React from 'react';
import {Button, TextField, useMediaQuery} from "@material-ui/core";
import {ApplicationContext, DISCLAIMER} from "../Shared/Shared";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {useState} from "react";
import {PleaseWait} from "../PleaseWait/PleaseWait";
import {GenderOptions} from "./GenderOptions";
import * as Sentry from "@sentry/react";
import {useContext} from "react";

interface Props {
    onSubmitSuccessful: () => void;
    onSkip: () => void;
}

export const OnboardingForm: React.FC<Props> = (props) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [gender, setGender] = useState<string | null>(null);
    const [age, setAge] = useState<number | null>(null);
    const [zipcode, setZipcode] = useState<string | null>(null);
    const [yearsAtCompany, setYearsAtCompany] = useState<number | null>(null);
    const [currentRole, setCurrentRole] = useState<string | null>(null);

    const {showGlobalError} = useContext(ApplicationContext);

    const isSmallScreen = useMediaQuery('(max-width:500px)');

    const handleSave = async () => {
        try {
            setIsLoading(true);
            await JourneyApiClient.saveOnBoardingAnswers({
                gender,
                age,
                zipcode,
                yearsAtCompany,
                currentRole,
                underMedicalCareLast6Months: null
            });
            props.onSubmitSuccessful();

        } catch (error) {
            setIsLoading(false);
            Sentry.captureException(error);
            showGlobalError("Could not save onboarding checkin.");
        }
    };

    if(isLoading) {
        return (
            <div className='onboarding-form-component'>
                <PleaseWait />
            </div>
        );

    } else {
        return(
            <div className='onboarding-form-component paper-container'>
                <h1>  Tell us a little about you </h1>

                <div className="title-caption">
                    {DISCLAIMER}
                </div>

                <div className='field-row'>

                    <GenderOptions
                        fieldName="gender"
                        value={gender}
                        handleChange={(name: string, value: string | null) => setGender(value) }
                    />

                    <TextField
                        className="field"
                        label="Age"
                        variant="outlined"
                        type="number"
                        value={age || ""}
                        onChange={(e) => setAge(parseInt(e.target.value))}
                    />

                </div>

                <div className='field-row'>

                    <TextField
                        className="field"
                        label="Zipcode"
                        variant="outlined"
                        value={zipcode || ""}
                        onChange={(e) => setZipcode(e.target.value)}
                    />

                    <TextField
                        className="field"
                        label="Years at Company"
                        variant="outlined"
                        type="number"
                        value={yearsAtCompany || ""}
                        onChange={(e) => setYearsAtCompany(parseInt(e.target.value))}
                    />

                </div>

                <TextField
                    className="field"
                    label="Current Role"
                    variant="outlined"
                    value={currentRole || ""}
                    onChange={(e) => setCurrentRole(e.target.value)}
                />

                <Button type='submit' className='field paper-submit-button' onClick={handleSave}>
                    Submit
                </Button>

                {
                    isSmallScreen &&
                    <Button className='field skip-button' onClick={props.onSkip}>
                        Skip
                    </Button>
                }

            </div>
        );
    }

};
