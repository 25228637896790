import React, {useEffect, useState} from 'react';
import {RegisterHomePage} from "./pages/RegisterHomePage/RegisterHomePage";
import {Switch, Route} from "react-router-dom";
import {NotFoundPage} from "./pages/NotFound/NotFound";
import {useLocation} from "react-router-dom";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import './App.scss';
import {LoginPage} from "./pages/LoginPage/LoginPage";
import {
    ApplicationContextType, CHECKIN_PATH, DAILY_JOURNEY_PATH,
    DEMO_RESET_PATH,
    GlobalError, GlobalWarning,
    Logout, RECOMMENDATIONS_PATH,
    REGISTER_THANKS_PATH, RESOURCES_PATH, MARKETING_OPT_IN, DAILY_VIDEO_PATH, WELCOME_PATH
} from "./components/Shared/Shared";
import {User} from "journey-shared/journey/ApiTypes";
import {ApplicationContext} from "./components/Shared/Shared";
import {CheckinPage} from "./pages/CheckinPage/CheckinPage";
import {RegisterThankYouPage} from "./pages/RegisterThankYouPage/RegisterThankYouPage";
import * as Sentry from "@sentry/react";
import {ResourcesPage} from "./pages/ResourcesPage/ResourcesPage";
import {DemoResetPage} from "./pages/DemoResetPage/DemoResetPage";
import {DailyJourneyPage} from "./pages/DailyJourneyPage/DailyJourneyPage";
import {RecommendationsPage} from "./pages/RecommendationsPage/RecommendationsPage";
import {MarketingOptInPage} from "./pages/MarketingOptInPage/MarketingOptInPage";
import {DailyVideoPage} from "./pages/DailyVideoPage/DailyVideoPage";
import {WelcomePage} from "./pages/WelcomePage/WelcomePage";
import ClientConfig from 'journey-shared/journey/ClientConfig';

function App() {

    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [globalError, setGlobalError] = useState<string | null>(null);
    const [globalWarning, setGlobalWarning] = useState<string | null>(null);
    const [isFetchingCurrentUser, setFetchingCurrentUser] = useState<boolean>(true);
    const location = useLocation();
    const getToken = () => {
        const query = new URLSearchParams(location.search);
        return query.get("token");
    };

    useEffect(() => {
        let token = getToken();
        if (token) {
            JourneyApiClient.setAuthCredentials(token);
        } else {
            token = JourneyApiClient.getAuthCredentials();
        }
        if (token) {
            JourneyApiClient.getCurrentUser()
                .then(user => {
                    //redirect user to new application if url is not marketing-opt-in
                    if(!location.pathname?.startsWith("/marketing-opt-in")) {
                        let newFrontendRoot = ClientConfig.newFrontendRoot || "https://app2.journey.live";
                        window.location.replace(`${newFrontendRoot}${location.pathname}`);
                    }
                    setCurrentUser(user);
                })
                .catch(error => {
                    // if to avoid showing the global error if user is not found in register page
                    // user is not needed in register page
                    if(!location.pathname?.startsWith("/register/")) {
                        setGlobalError(error.message || error);
                    }
                    Sentry.captureException(error);
                }).finally(() => setFetchingCurrentUser(false));
        } else {
            setFetchingCurrentUser(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const appContext: ApplicationContextType = {
        currentUser: currentUser,
        isFetchingCurrentUser: isFetchingCurrentUser,
        setCurrentUser: setCurrentUser,
        showGlobalError: setGlobalError,
        showGlobalWarning: setGlobalWarning,
    };

    return (
        <div className="app-container journey-gradient-bg">
            <ApplicationContext.Provider value={appContext}>
                <Switch>
                    <Route path="/register/:companyCode">
                        <RegisterHomePage />
                    </Route>

                    <Route path={REGISTER_THANKS_PATH}>
                        <RegisterThankYouPage />
                    </Route>

                    <Route path={CHECKIN_PATH}>
                        <CheckinPage />
                    </Route>

                    <Route path={DEMO_RESET_PATH}>
                        <DemoResetPage />
                    </Route>

                    <Route path={RECOMMENDATIONS_PATH}>
                        <RecommendationsPage />
                    </Route>

                    <Route path={RESOURCES_PATH}>
                        <ResourcesPage />
                    </Route>

                    <Route path={DAILY_JOURNEY_PATH}>
                        <DailyJourneyPage />
                    </Route>

                    <Route path={MARKETING_OPT_IN}>
                        <MarketingOptInPage />
                    </Route>

                    <Route path={DAILY_VIDEO_PATH}>
                        <DailyVideoPage />
                    </Route>

                    <Route path={WELCOME_PATH}>
                        <WelcomePage />
                    </Route>

                    <Route path="/login">
                        <LoginPage />
                    </Route>

                    <Route path="/logout">
                        <Logout />
                    </Route>

                    <Route path="/:companyCode">
                        <RegisterHomePage />
                    </Route>

                    <Route path="*">
                        <NotFoundPage />
                    </Route>
                </Switch>

                <GlobalError message={globalError} onClose={() => setGlobalError(null)} />

                <GlobalWarning message={globalWarning} onClose={() => setGlobalWarning(null)} />

            </ApplicationContext.Provider>
        </div>
    );
}

export default App;


