import './ResourcesPage.scss';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {ApplicationContext} from "../../components/Shared/Shared";
import {useHistory} from "react-router-dom";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {GetCompanyBenefitsInfoResponse, RESOURCES_VISIT} from "journey-shared/journey/ApiTypes";
import * as Sentry from "@sentry/react";
import {extractErrorMessage} from "../../common/utils";
import {NavigationBar} from "../../components/NavigationBar/NavigationBar";
import {Resources} from "../../components/Resources/Resources";

export const ResourcesPage: React.FC = () => {

    const {currentUser, showGlobalError, isFetchingCurrentUser, showGlobalWarning} = useContext(ApplicationContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [companyBenefitsInfo, setCompanyBenefitsInfo] = useState<GetCompanyBenefitsInfoResponse | null>(null);
    const history = useHistory();

    useEffect(() => {
        JourneyApiClient.getCompanyBenefitsInfo()
            .then(setCompanyBenefitsInfo)
            .then(() => JourneyApiClient.createUserEngagementEvent(RESOURCES_VISIT))
            .catch((error) => {
                if (error.response?.status === 401) {
                    showGlobalWarning("Please Sign In");
                } else {
                    Sentry.captureException(error);
                    showGlobalError(extractErrorMessage(error));
                }
            })
            .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []);


    return (
        <div className='resources-page'>
            <NavigationBar />

            {
                (function() {
                    if(!isFetchingCurrentUser && !currentUser) {
                        setTimeout(() => history.push("/login"), 1000);
                        return <PleaseWait />;
                    } else if (isLoading) {
                        return <PleaseWait />;
                    } else if (companyBenefitsInfo && !isLoading) {
                        return <Resources benefitsInfo={companyBenefitsInfo}  />;
                    } else {
                        return <div>Something went wrong.</div>;
                    }
                })()
            }
        </div>
    );

};
