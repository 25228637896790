import "./MarketingOptInPage.scss";
import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {Logo} from "../../components/Logo/Logo";
import {SuccessMessage} from "../../components/SuccessMessage/SuccessMessage";
import {ApplicationContext} from "../../components/Shared/Shared";
import * as Sentry from "@sentry/react";

export const MarketingOptInPage: React.FC = () => {

    const history = useHistory();
    const [isMarketingOptInCompleted, setMarketingOptInCompleted] = useState<boolean>(false);
    const {currentUser, isFetchingCurrentUser, showGlobalError} = useContext(ApplicationContext);

    useEffect(() => {
        if(currentUser) {
            JourneyApiClient.userMarketingOptIn()
                .then((response: any) => {
                    setMarketingOptInCompleted(true);
                }).catch((error) => {
                    Sentry.captureException(error);
                    showGlobalError("Unable to opt in marketing emails. Try again!");
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    const componentWrapper = (content: JSX.Element): JSX.Element => {

      return(
          <div className='marketing-page'>
            <div className='header'>
                <Logo />
            </div>

            <div style={{clear: 'both'}} />

            <div className='body'>
                {content}
            </div>
          </div>
      );
    };

    if(!isFetchingCurrentUser && !currentUser ) {
        setTimeout(() => history.push("/login"), 1000);
        return(componentWrapper(<PleaseWait/>));
    } else if(isMarketingOptInCompleted) {
        return(componentWrapper(<SuccessMessage isResourcesNeeded={false}/>));
    }  else {
        return(componentWrapper(<PleaseWait/>));
    }
};
