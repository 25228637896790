import "./CheckinPage.scss";
import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {CHECKIN_VISIT, FormName} from "journey-shared/journey/ApiTypes";
import {Logo} from "../../components/Logo/Logo";
import {DailyCheckinForm} from "../../components/DailyCheckinForm/DailyCheckinForm";
import {MonthlyCheckinForm} from "../../components/MonthlyCheckinForm/MonthlyCheckinForm";
import {SuccessMessage} from "../../components/SuccessMessage/SuccessMessage";
import {ApplicationContext} from "../../components/Shared/Shared";
import {NoneMessage} from "../../components/NoneMessage/NoneMessage";
import {OnboardingForm} from "../../components/OnBoardingForm/OnboardingForm";
import * as Sentry from "@sentry/react";
import {useMediaQuery} from "@material-ui/core";


export const CheckinPage: React.FC = () => {

    const history = useHistory();
    const [isAssessmentReady, setAssessmentReady] = useState<boolean>(false);
    const [vimeoUrl, setVimeoUrl] = useState<string>("https://portal.journey.live/browse");

    const {currentUser, isFetchingCurrentUser, showGlobalError} = useContext(ApplicationContext);
    const isSmallScreen = useMediaQuery('(max-width:500px)');

    const [isResourcesNeeded, setResourcesNeeded] = useState<boolean>(false);

    useEffect(() => {
        if(currentUser) {
            setVimeoUrl(`${vimeoUrl}/?ticket=${currentUser.vimeoAuthToken}`);
            JourneyApiClient.getCurrentAssessment()
                .then((code: FormName) => {

                    JourneyApiClient.createUserEngagementEvent(CHECKIN_VISIT);

                    setTimeout(() => {
                        history.replace(`/checkin/${code}`)
                        setAssessmentReady(true);
                    }, 1000);
                }).catch((error) => {
                    Sentry.captureException(error);
                    showGlobalError("Unable to fetch current assessment");
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);


    const onSubmitForm = (formName: FormName) => {
        setTimeout(() => history.push(`/checkin/thank-you/${formName}`), 1000);
    };

    const onSubmitOnBoarding = () => {
        setTimeout(() => history.push("/checkin/monthly"), 1000);
    };

    const onSkip = () => {
        window.location.replace(vimeoUrl);
    };

    const redirectToResources = () => {
        setResourcesNeeded(true);
    };

    const componentWrapper = (content: JSX.Element): JSX.Element => {

      return(
          <div className='checkin-page'>
              {
                  isAssessmentReady &&
                  <div className='header'>
                      <Logo />

                      {
                            !isSmallScreen &&
                            <a className='skip-button' href={vimeoUrl}> Skip </a>
                      }
                  </div>
              }

              <div style={{clear: 'both'}} />

              <div className='body'>
                  {content}
              </div>
          </div>
      );
    };

    const getMainContent = (): JSX.Element => {
          return(
              <Switch>
                  <Route path="/checkin/daily">
                      <DailyCheckinForm onSubmitSuccessful={() => onSubmitForm("daily")} />
                  </Route>
                  <Route path="/checkin/monthly">
                      <MonthlyCheckinForm onSubmitSuccessful={() => onSubmitForm("monthly")} redirectToResources={redirectToResources} />
                  </Route>
                  <Route path="/checkin/onboarding">
                      <OnboardingForm onSubmitSuccessful={onSubmitOnBoarding} onSkip={onSkip} />
                  </Route>
                  <Route path="/checkin/none">
                      <NoneMessage />
                  </Route>
                  <Route path="/checkin/thank-you/:assessmentCode">
                      <SuccessMessage isResourcesNeeded={isResourcesNeeded} />
                  </Route>
              </Switch>
          );
    };

    if(!isFetchingCurrentUser && !currentUser ) {
        setTimeout(() => history.push("/login"), 1000);
        return(componentWrapper(<PleaseWait/>));
    } else if(isAssessmentReady) {
        return (componentWrapper(getMainContent()));
    }  else {
        return(componentWrapper(<PleaseWait/>));
    }
};
