import './RegisterHomePage.scss';
import * as React from 'react';
import {SignUpForm} from "../../components/SignUpForm/SignUpForm";
import {Logo} from "../../components/Logo/Logo";

export const RegisterHomePage: React.FC = () => {

    return (
        <div className='register-home-page'>
            <div className='header'>
                <Logo />
            </div>

            <div className='body'>
                <SignUpForm />
            </div>
        </div>
    );


};
