import "./WelcomePage.scss";
import * as React from 'react';
import {useContext} from "react";
import {useHistory} from "react-router-dom";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import {Logo} from "../../components/Logo/Logo";
import {SuccessMessage} from "../../components/SuccessMessage/SuccessMessage";
import {ApplicationContext} from "../../components/Shared/Shared";

export const WelcomePage: React.FC = () => {

    const history = useHistory();
    const {currentUser, isFetchingCurrentUser } = useContext(ApplicationContext);

    const componentWrapper = (content: JSX.Element): JSX.Element => {

      return(
          <div className='welcome-page'>
            <div className='header'>
                <Logo />
            </div>

            <div style={{clear: 'both'}} />

            <div className='body'>
                {content}
            </div>
          </div>
      );
    };

    if(!isFetchingCurrentUser && !currentUser ) {
        setTimeout(() => history.push("/login"), 1000);
        return(componentWrapper(<PleaseWait/>));
    } else if(isFetchingCurrentUser) {
        return(componentWrapper(<PleaseWait/>));
    }  else {
        return(componentWrapper(<SuccessMessage isResourcesNeeded={false} isWelcome={true}/>));
    }
};
