import * as React from 'react';
import {ApiDailyJourney} from "journey-shared/journey/ApiTypes";
import LogoPic from "../../assets/images/journey-logo-white.svg";
import "./DailyJourney.scss";

interface Props {
    dailyJourney: ApiDailyJourney;
}

export const DailyJourney: React.FC<Props> = ({dailyJourney}) => {

    const formattedDate = new Date(dailyJourney.publicationDate);
    const dateString = formattedDate.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' });

    return(
        <div className='daily-journey-component'>

            <div className='header'>
                <div className='inner'>
                    <img src={LogoPic} alt="Journey Logo" className='logo' />

                    <div className='date'>
                        Daily Journey: {dateString}
                    </div>

                    <div className='title'>
                        {dailyJourney.theme}
                    </div>
                </div>
            </div>

            <div className='body'>

                <div className='inner'  dangerouslySetInnerHTML={{__html: dailyJourney.body ?? ""}} />

                {
                    (dailyJourney.videoDescription && dailyJourney.videoCoverImageUrl) &&
                    <div className='inner'>
                        <h1>Got a few more?</h1>

                        <div className='video-info'>

                            <div className='column'>
                                <img src={dailyJourney.videoCoverImageUrl} alt='Daily Journey Video Cover' />
                            </div>

                            <div className='column info' dangerouslySetInnerHTML={{__html: dailyJourney.videoDescription ?? ""}} />
                        </div>

                    </div>

                }

            </div>

            <div className='divider' />
        </div>
    );
}
