import "./SuccessMessage.scss";
import * as React from 'react';
import {useContext, useEffect} from "react";
import {LinearProgress} from "@material-ui/core";
import {ApplicationContext, RECOMMENDATIONS_PATH, SuccessIcon, RESOURCES_PATH, CHECKIN_PATH} from "../Shared/Shared";
import {useHistory, useParams} from "react-router-dom";

interface Props {
    isResourcesNeeded: boolean;
    isWelcome?: boolean;
}

export const SuccessMessage: React.FC<Props> = (props) => {

    const {currentUser} = useContext(ApplicationContext);
    let { assessmentCode } = useParams<{assessmentCode: string}>();
    const history = useHistory();

    const isMonthlyAssessment = assessmentCode === "monthly";

    useEffect(() => {
        if(currentUser) {

            setTimeout(() => {
                if (isMonthlyAssessment) {
                    if(props.isResourcesNeeded) {
                        history.replace(RESOURCES_PATH);
                    } else {
                        history.replace(RECOMMENDATIONS_PATH);
                    }
                } else if(props.isWelcome) {
                    let welcomeVideo = `https://portal.journey.live/featured/videos/journey-welcome-vid?ticket=${currentUser.vimeoAuthToken}`
                    window.location.replace(welcomeVideo);
                } else {
                    history.push(CHECKIN_PATH);
                }
            }, 3000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    return(
        <div className="success-message-component paper-container">

            <SuccessIcon />

            <div className='content animate__animated animate__fadeIn'>
                {
                    isMonthlyAssessment && props.isResourcesNeeded ? "Thank You! We have additional resources available to support you." :
                    isMonthlyAssessment ? "Thank You! We are preparing personal recommendations for you." :
                    props.isWelcome ? "Welcome to Journey LIVE!" :
                        "Thank You! You'll now be redirected to Journey LIVE."
                }
            </div>

            <LinearProgress className='progress animate__animated animate__fadeIn' />
        </div>
    )

};
