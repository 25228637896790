import "./PleaseWait.scss";
import * as React from 'react';
import {CircularProgress} from "@material-ui/core";

export const PleaseWait: React.FC = () => {
    return(
        <div className="please-wait-component">
            <div className="content animate__animated animate__fadeIn">
                <h2>Please Wait</h2>
                <CircularProgress className="loading-spinner" size={100} />
            </div>
        </div>
    )
};
