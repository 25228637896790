import * as React from 'react';
import {Button, TextField} from "@material-ui/core";
import "./LoginForm.scss";
import {useState} from "react";
import {PleaseWait} from "../PleaseWait/PleaseWait";
import {ApplicationContext, SuccessIcon} from "../Shared/Shared";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import * as Sentry from "@sentry/react";
import {useContext} from "react";

export const LoginForm: React.FC = () => {


    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [email, setEmail] = useState<string | null>(null);

    const {showGlobalError} = useContext(ApplicationContext);

    const onSubmit = () => {
        if (email) {
            setIsLoading(true);
            JourneyApiClient.sendLoginEmail(email)
                .then(() => {
                    setIsSubmitted(true);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    Sentry.captureException(error);
                    showGlobalError("Could not send login instructions.");
                });
        }
    };

    const mainContent =
        <div className="login-form-component paper-container">
            <h1>Please Login First</h1>
            <form action='#' onSubmit={onSubmit}>
                <TextField
                    className="field"
                    variant="outlined"
                    label="Email"
                    type="email"
                    required={true}
                    value={email || ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                />
                <Button type='submit' className='field paper-submit-button'>
                    Next
                </Button>
            </form>
        </div>;

    const submittedContent =
        <div className="login-form-component paper-container">
            <h1>Login</h1>

            <SuccessIcon />

            <div className='animate__animated animate__fadeIn'>
                Thank You! Your login link has been sent to your email. You may now close this window.
            </div>

        </div>;


    if (isLoading) {
        return <PleaseWait />;
    } else if(isSubmitted) {
        return submittedContent;
    } else {
        return mainContent;
    }

};
