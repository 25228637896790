import * as React from "react";
import {FormControlLabel, Radio, RadioGroup, useMediaQuery} from "@material-ui/core";


interface Props {
    fieldName: string;
    value: number | null;
    onChange: (fieldName: string, value: number) => void;
}

export const FeelingRatingQuestion: React.FC<Props> = (props) => {

    const handleChangeInternal = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        props.onChange(props.fieldName, event.target.value as number);
    };

    const isSmallScreen = useMediaQuery('(max-width:680px)');

    const labelPlacement = isSmallScreen ? "end" : "bottom";

    return(
        <RadioGroup
            className="feeling-rating-question-component"
            row aria-label="position"
            name="position"
            defaultValue="top"
            value={props.value}
            onChange={handleChangeInternal} >

                <FormControlLabel value="1" control={<Radio color="primary" />}
                                  label="Very Bad" labelPlacement={labelPlacement} />

                <FormControlLabel value="2" control={<Radio color="primary" />}
                                  label="Bad" labelPlacement={labelPlacement} />

                <FormControlLabel value="3" control={<Radio color="primary" />}
                                  label="Okay" labelPlacement={labelPlacement} />

                <FormControlLabel value="4" control={<Radio color="primary" />}
                                  label="Good" labelPlacement={labelPlacement} />

                <FormControlLabel value="5" control={<Radio color="primary" />}
                                  label="Great" labelPlacement={labelPlacement} />

                <FormControlLabel value="6" control={<Radio color="primary" />}
                                  label="Excellent" labelPlacement={labelPlacement} />
        </RadioGroup>
    );
};
