import "./GoalsQuestion.scss";
import * as React from 'react';
import {Checkbox, FormControlLabel, FormGroup, TextField} from "@material-ui/core";
import {useEffect, useState} from "react";

interface Props {
    onSelectedValueChanged: (values: string[]) => void;

    setOtherOptionValue: (value: string | null) => void;
    otherOptionValue: string | null;

    setValid: (valid: boolean) => void;
}

interface Option {
    value: string;
    label: string;
}

export const GoalsQuestion: React.FC<Props> = (props) => {

    const createOption = (value: string, label: string): Option => {
        return { value, label }
    };

    const options: Option[] = [
        createOption("managing_stress", "Managing Stress"),
        createOption("improving_sleep", "Improving Sleep"),
        createOption("reducing_anxiety", "Reducing Anxiety"),
        createOption("improving_your_relationships", "Improving Your Relationships"),
        createOption("finding_happiness", "Finding Happiness"),
        createOption("staying_productive", "Staying Productive"),
        createOption("better_communication", "Better Communication"),
        createOption("finding_work_life_balance", "Finding Work-Life Balance"),
    ];
    const initialState: boolean[] = options.map( _ => false);

    const [otherSelected, setOtherSelected] = useState<boolean>(false);
    const [selectedOptions, setSelectedOptions] = useState<boolean[]>(initialState);

    const onSelectedOptionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValues = Array(...selectedOptions);
        newValues[parseInt(event.target.name)] = event.target.checked;
        setSelectedOptions(newValues);
    };

    const handleOtherSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOtherSelected(event.target.checked);
        if (!event.target.checked) {
            props.setOtherOptionValue("");
        }
    };

    const onOtherValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setOtherOptionValue(event.target.value);
        if(!event.target.value) {
            setOtherSelected(false);
        } else {
            setOtherSelected(true);
        }
    };

    const onOtherValueBlur = () => {
        if (!props.otherOptionValue) {
            setOtherSelected(false);
        }
    };

    const onSelectedValuesChangedInternal = () => {
        const selectedOptionsValues: string[] = [];
        selectedOptions.forEach((selected: boolean, index: number) => {
            if(selected) {
                selectedOptionsValues.push(options[index].value)
            }
        });
        if (props.otherOptionValue) {
            selectedOptionsValues.push("other");
        }
        props.onSelectedValueChanged(selectedOptionsValues);
    };

    useEffect(() => {
        const validOtherValue: boolean = !!(otherSelected && props.otherOptionValue);
        const selectedCount: number = selectedOptions.filter((v) => v).length + (props.otherOptionValue ? 1 : 0);
        props.setValid(selectedCount <= 3 && (!otherSelected || validOtherValue));
        onSelectedValuesChangedInternal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptions, props.otherOptionValue, otherSelected]);


    return(
        <div className="goals-question-component">
            <FormGroup className="checkbox-group">
                {
                    selectedOptions.map((selectedOption: boolean, index: number) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox checked={selectedOption} color="primary" onChange={onSelectedOptionsChange}
                                          name={index.toString()}/>
                            }
                            label={options[index].label}
                        />
                    ))
                }

                <div className="field-row">
                    <FormControlLabel
                        control={
                            <Checkbox color="primary" checked={otherSelected} onChange={handleOtherSelected}/>
                        }
                        label="Other"
                    />
                    <TextField
                        className="other-input-field"
                        variant="outlined"
                        onChange={onOtherValueChange}
                        onBlur={onOtherValueBlur}
                        value={props.otherOptionValue || ""}
                        onFocus={() => setOtherSelected(true)}
                    />
                </div>

            </FormGroup>
        </div>
    );
};
