import './DailyCheckinForm.scss';
import * as React from 'react';
import {Button, useMediaQuery} from "@material-ui/core";
import {useContext, useState} from "react";
import {FeelingRatingQuestion} from "./FeelingRatingQuestion";
import {ApplicationContext, DISCLAIMER} from "../Shared/Shared";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {FormName} from "journey-shared/journey/ApiTypes";
import {PleaseWait} from "../PleaseWait/PleaseWait";
import * as Sentry from "@sentry/react";


interface Props {
    onSubmitSuccessful: (name: FormName) => void;
}

export const DailyCheckinForm: React.FC<Props> = (props) => {

    const [feelingRating, setFeelingRating] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isSmallScreen = useMediaQuery('(max-width:500px)');
    const {showGlobalError} = useContext(ApplicationContext);

    const handleSave = async () => {
        try {
            setIsLoading(true);
            await JourneyApiClient.saveDailyCheckinAnswers({
                feelingRating: feelingRating,
                priorities: "[]",
                otherPriority: null
            });
            props.onSubmitSuccessful("daily");

        } catch (error) {
            setIsLoading(false);
            Sentry.captureException(error);
            showGlobalError("Could not save daily checkin.");
        }
    };

    if(isLoading) {
        return (
            <div className='monthly-checkin-form-component'>
                <PleaseWait />
            </div>
        );

    } else {

        return (
            <div className='daily-checkin-form-component paper-container'>
                <h1> Daily Check-In </h1>

                <div className='title-caption'>
                    {DISCLAIMER}
                </div>

                <div className='long-text'>
                    How are you feeling today?

                    <FeelingRatingQuestion
                        fieldName="feelingRating"
                        value={feelingRating}
                        onChange={(_: string, value: number) => setFeelingRating(value)}/>

                </div>

                <Button type='submit' className='field paper-submit-button' onClick={handleSave}>
                    Submit
                </Button>

                {
                    isSmallScreen &&
                    <Button className='field skip-button'  onClick={handleSave}>
                        Skip
                    </Button>
                }

            </div>
        );
    }
};
