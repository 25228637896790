import * as React from 'react';


interface Props {
    isInvalidUrl: boolean;
    errorMessage: string | null;
    validationErrorMessage: string | null;
}


export const ErrorMessage: React.FC<Props> = (props: Props) =>  {

    if (props.isInvalidUrl) {
        return <div className='error-content'>
            <div className='header'>
                Invalid registration URL
            </div>
            <div className='body'>
                You are receiving this message because this URL is not tied to an active client.
                Please check the link that you were provided and if you still are having issues,
                contact us at <a href="mailto:info@journey.live">info@journey.live</a>
            </div>
        </div>;
    } else if (props.validationErrorMessage) {
        return <div className='error-content'>
            <div className='header'>
                Validation Error
            </div>
            <div className='body'>
                {props.validationErrorMessage}
            </div>
            <div className='body'>
                If problems persist please contact us at <a href="mailto:info@journey.live">info@journey.live</a>
            </div>
        </div>;
    } else {
        return <div className='error-content'>
            <div className='header'>
                Internal Application Error
            </div>
            <div className='body'>
                {props.errorMessage}
            </div>
            <div className='body'>
                Please contact us at <a href="mailto:info@journey.live">info@journey.live</a>
            </div>
        </div>;
    }
};
