import React, {MouseEventHandler, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {AppBar, IconButton, Toolbar} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import LogoPic from '../../assets/images/journey-logo-blue.svg';
import "./NavigationBar.scss";
import {useHistory} from "react-router-dom";
import {CHECKIN_PATH, DAILY_JOURNEY_PATH, RECOMMENDATIONS_PATH, RESOURCES_PATH} from "../Shared/Shared";

//TODO: Do not use makeStyles
const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export const NavigationBar: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setIsOpen(open);
    };

    const links: {label: string, handler: MouseEventHandler}[] = [
        {label: "Browse", handler: () => window.location.href = "https://portal.journey.live/browse"},
        {label: "Search", handler: () => window.location.href = "https://portal.journey.live/search"},
        {label: "Daily Journey", handler: () => history.push(DAILY_JOURNEY_PATH)},
        {label: "Check-in", handler: () => history.push(CHECKIN_PATH)},
        {label: "Recommendations", handler: () => history.push(RECOMMENDATIONS_PATH)},
        {label: "Resources", handler: () => history.push(RESOURCES_PATH)},
    ];


    const list = () => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: true,
            })}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {links.map((link, index) => (
                    <ListItem button key={link.label} onClick={link.handler}>
                        <ListItemText primary={link.label} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <AppBar position="static" className="navigation-bar-component">
            <Toolbar className="toolbar">
                <IconButton edge="start" className="toggle-menu-button" color="inherit" aria-label="menu" onClick={toggleDrawer( true)}>
                    <MenuIcon />
                </IconButton>

                <img src={LogoPic} className="logo" alt="Journey Logo" onClick={() => window.location.href = "https://portal.journey.live/browse"} />

                <div  className="full-screen-links">
                    {
                        links.map((link, index) =>
                            <Button className='nav-button' key={index} onClick={link.handler}>{link.label}</Button>)
                    }
                </div>

            </Toolbar>
            <Drawer anchor={"top"} open={isOpen} onClose={toggleDrawer(false)}>
                { list() }
            </Drawer>
        </AppBar>
    );
}
