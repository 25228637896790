import * as React from "react";
import SuccessIconSrc from "../../assets/images/check-circle-regular.svg";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {User} from "journey-shared/journey/ApiTypes";
import {Snackbar, SnackbarOrigin} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {useEffect} from "react";

export const DISCLAIMER = "This information will not be shared with your employer in a personally identifiable way. All questions are optional.";


export const SuccessIcon: React.FC = () => {
    return(
        <img
            style={{
                height: "100px",
                margin: "30px auto",
                display: "block",
            }}
            className='success-icon animate__animated animate__bounceIn'
            alt="Success Icon"
            src={SuccessIconSrc} />
    )
};


export const Logout: React.FC = () => {
    JourneyApiClient.logout();
    window.location.replace(`https://portal.journey.live/browse`);
    return <div />;
};



export interface ApplicationContextType {
    currentUser: User | null;
    isFetchingCurrentUser: boolean;
    setCurrentUser: (user: User) => void;
    showGlobalError: (message: string) => void;
    showGlobalWarning: (message: string) => void;
}

export const ApplicationContext: React.Context<ApplicationContextType> =
    React.createContext<ApplicationContextType>({
        currentUser: null,
        showGlobalError: (_) => {},
        showGlobalWarning: (_) => {},
        isFetchingCurrentUser: false,
        setCurrentUser: () => {
            throw new Error("setCurrentUser is not implemented ")
        }
    });


export const REGISTER_THANKS_PATH = "/register-thanks";
export const DEMO_RESET_PATH = "/demo-reset";
export const RESOURCES_PATH = "/resources";
export const CHECKIN_PATH = "/checkin";
export const DAILY_JOURNEY_PATH = "/daily-journey";
export const RECOMMENDATIONS_PATH = "/recommendations";
export const MARKETING_OPT_IN = "/marketing-opt-in";
export const DAILY_VIDEO_PATH = "/daily-video";
export const WELCOME_PATH = "/welcome";
export const RESOURCES_EMBEDDED_PATH = "/test-dc06c54c-2a3c-4769-8ffd-1698f9484667";

interface GlobalErrorProps {
    message: string | null;
    onClose: () => void;
}

export const GlobalError: React.FC<GlobalErrorProps> = (props) => {
    const position: SnackbarOrigin = {vertical: "top", horizontal: "center"};
    if (!props.message) {
        return <div />;
    } else {
        return (
            <Snackbar open={!!props.message} onClose={props.onClose} anchorOrigin={position}>
                <Alert elevation={6} variant="filled" severity="error">
                    <div style={{fontWeight: "bold"}}>
                        {props.message}
                    </div>
                    <div>
                        Please contact us at info@journey.live
                    </div>
                </Alert>
            </Snackbar>
        );
    }
};

export const GlobalWarning: React.FC<GlobalErrorProps> = ({message, onClose}) => {

    useEffect(()=> {
        if(message) {
            setTimeout(onClose, 3000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    const position: SnackbarOrigin = {vertical: "top", horizontal: "center"};
    if (!message) {
        return <div />;
    } else {
        return (
            <Snackbar open={!!message} onClose={onClose} anchorOrigin={position}>
                <Alert elevation={6} variant="filled" severity="warning">
                    <div style={{fontWeight: "bold"}}>
                        {message}
                    </div>
                </Alert>
            </Snackbar>
        );
    }
};


