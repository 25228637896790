import './RecommendationsPage.scss';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {ApplicationContext} from "../../components/Shared/Shared";
import {useHistory} from "react-router-dom";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApiRecommendation, RECOMMENDATIONS_VISIT} from "journey-shared/journey/ApiTypes";
import * as Sentry from "@sentry/react";
import {extractErrorMessage} from "../../common/utils";
import {NavigationBar} from "../../components/NavigationBar/NavigationBar";
import {RecommendationsList} from "../../components/RecommendationsList/RecommendationsList";

export const RecommendationsPage: React.FC = () => {

    const {currentUser, showGlobalError, isFetchingCurrentUser, showGlobalWarning} = useContext(ApplicationContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [recommendations, setRecommendations] = useState<ApiRecommendation[]>([]);
    const history = useHistory();

    useEffect(() => {
        JourneyApiClient.getRecommendations()
            .then(setRecommendations)
            .then(() => JourneyApiClient.createUserEngagementEvent(RECOMMENDATIONS_VISIT))
            .catch((error) => {
                if (error.response?.status === 401) {
                    showGlobalWarning("Please Sign In");
                } else {
                    Sentry.captureException(error);
                    showGlobalError(extractErrorMessage(error));
                }
            })
            .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []);


    return (
        <div className='recommendations-page'>

            <NavigationBar />

            {
                (function() {
                    if (!isFetchingCurrentUser && !currentUser) {
                        setTimeout(() => history.push("/login"), 1000);
                        return <PleaseWait />;
                    } else if (isLoading) {
                        return <PleaseWait />;
                    } else if (!isLoading && currentUser) {
                        return <RecommendationsList currentUser={currentUser} recommendations={recommendations} />;
                    } else {
                        return <div>Something went wrong.</div>;
                    }
                })()
            }
        </div>
    );

};
