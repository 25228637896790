import * as React from 'react';
import {ApiVimeoVideoDetails} from "journey-shared/journey/ApiTypes";
import "./DailyVideo.scss";

interface Props {
    dailyVideo: ApiVimeoVideoDetails;
}

export const DailyVideo: React.FC<Props> = ({dailyVideo}) => {

    return(
        <div className='daily-video-component'>

            <div className='body'>
                <div className="video-iframe" dangerouslySetInnerHTML={{__html: dailyVideo.html}} />
            </div>

        </div>
    );
}
