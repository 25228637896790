import './Logo.scss';
import * as React from 'react';

import LogoPic from '../../assets/images/journey-logo.png';

export const Logo: React.FC = () => {
    return(
        <div className='logo-component animate__animated animate__bounceIn'>
            <img className='logo' src={LogoPic} alt='Journey' />
            <div className='live-caption'>LIVE</div>
        </div>
    );
};
