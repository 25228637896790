import * as React from 'react';
import "./RecommendationsList.scss";
import {ApiRecommendation, User} from "journey-shared/journey/ApiTypes";
import {Link} from "react-router-dom";
import {CHECKIN_PATH} from "../Shared/Shared";

interface Props {
    recommendations: ApiRecommendation[];
    currentUser: User;
}

export const RecommendationsList: React.FC<Props> = ({recommendations, currentUser}) => {

    if(recommendations.length === 0) {
        return(
            <div className='recommendations-list-component'>
                <div className='no-recommendations'>
                    <p>
                        Please complete your Monthly Assessment so we can provide you with personal recommendations.
                    </p>

                    <Link className='white-button' to={CHECKIN_PATH}> Go To Assessment </Link>
                </div>
            </div>
        );
    } else {
        return(
            <div className='recommendations-list-component paper-container'>
                <h1> Personal Recommendations </h1>
                <p className='title-caption'>
                    Based on your assessment, we recommend the following content:
                </p>

                {
                    recommendations.map((recommendation, index: number) => {
                        return <div className='recommendation' key={index}>
                            <a href={`${recommendation.url}?ticket=${currentUser.vimeoAuthToken}`}>
                                <img src={recommendation.thumbnailUrl}
                                     alt='Video Thumbnail' className='thumbnail' />
                            </a>
                        </div>;
                    })
                }
            </div>
        );
    }

}
