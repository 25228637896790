import './RegisterThankYouPage.scss';
import * as React from 'react';
import {Logo} from "../../components/Logo/Logo";
import {SuccessMessage} from "../../components/SuccessMessage/SuccessMessage";

export const RegisterThankYouPage: React.FC = () => {

    return (
        <div className='register-thank-you-page'>
            <div className='header'>
                <Logo />
            </div>

            <div className='body'>
                <SuccessMessage isResourcesNeeded={false}/>
            </div>
        </div>
    );


};
