import * as React from "react";
import {MenuItem, Select, useMediaQuery} from "@material-ui/core";

interface Props {
    fieldName: string;
    value: number | null;
    handleChange: (fieldName: string, value: number | null) => void;
}


export const FrequencyOptions: React.FC<Props> = ({fieldName, value, handleChange}: Props) => {

    const handleChangeInternal = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        if(event.target.value) {
            handleChange(fieldName, event.target.value as number);
        } else {
            handleChange(fieldName, null);
        }
    };

    const isSmallScreen = useMediaQuery('(max-width:500px)');

    const options = [
        {value: 1, label: "Never"},
        {value: 2, label: "Almost Never"},
        {value: 3, label: "Sometimes"},
        {value: 4, label: "Fairly Often"},
        {value: 5, label: "Very Often"},
    ];

    if (isSmallScreen) {
        options.unshift({value: 0, label: ""})
    }

    return(
        <Select
            onChange={handleChangeInternal}
            className="field"
            native={isSmallScreen}
            value={value || ""}
            variant="outlined"
        >
            {
                options.map((opt, index) => {
                    return isSmallScreen ?
                        <option key={index} value={opt.value}>{opt.label}</option> :
                        <MenuItem key={index} value={opt.value}>{opt.label}</MenuItem>
                })
            }
        </Select>
    );
};
