import './DemoResetPage.scss';
import * as React from 'react';
import {Logo} from "../../components/Logo/Logo";
import {useContext, useState} from "react";
import {ApplicationContext, SuccessIcon} from "../../components/Shared/Shared";
import {useEffect} from "react";
import {LinearProgress} from "@material-ui/core";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {useHistory} from "react-router-dom";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";

export const DemoResetPage: React.FC = () => {

    const {currentUser, showGlobalError, isFetchingCurrentUser} = useContext(ApplicationContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const history = useHistory();

    useEffect(() => {
        if(currentUser) {
           JourneyApiClient.deleteJourneyUserCheckinAnswers()
               .then(() => {
                   setIsLoading(false);
                   setTimeout(() => {
                       history.push('/checkin')
                   }, 3000);
               })
               .catch(() => showGlobalError("Unable to reset account."))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);


    const successContent =
        <div className="success-message-component paper-container">
            <SuccessIcon />
            <div className='content animate__animated animate__fadeIn'>
                You will now be redirected to the initial assessment.
            </div>
            <LinearProgress className='progress animate__animated animate__fadeIn' />
        </div>;

    const getContent = () => {
        if(!isFetchingCurrentUser && !currentUser) {
            setTimeout(() => history.push("/login"), 1000);
            return <PleaseWait />;
        } else if (isLoading) {
            return <PleaseWait />;
        } else {
            return successContent;
        }
    };


    return (
        <div className='demo-reset-page'>
            <div className='header'>
                <Logo />
            </div>

            <div className='body'>
                { getContent()}
            </div>
        </div>
    );

};
