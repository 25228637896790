"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHECKIN_VISIT = exports.DAILY_JOURNEY_VISIT = exports.RESOURCES_VISIT = exports.RECOMMENDATIONS_VISIT = exports.ApiOnboardingAnswer = exports.ApiDailyCheckinAnswer = exports.ApiMonthlyCheckinAnswerV101 = exports.ApiMonthlyCheckinAnswer = void 0;
class ApiMonthlyCheckinAnswer {
    constructor() {
        this.stressLevel = null;
        this.manageStressLevel = null;
        this.feelingsFrequency = null;
        this.manageFeelingsLevel = null;
        this.feelingsFrequencyAffectWork = null;
        this.daysEmotionallyChallenging = null;
        this.priorities = null;
        this.otherPriority = null;
    }
}
exports.ApiMonthlyCheckinAnswer = ApiMonthlyCheckinAnswer;
class ApiMonthlyCheckinAnswerV101 {
    constructor() {
        this.stressLevel = null;
        this.feelingsFrequency = null;
        this.manageStressLevel = null;
        this.feelingsFrequencyAffectWork = null;
        this.daysEmotionallyChallenging = null;
        this.priorities = null;
    }
}
exports.ApiMonthlyCheckinAnswerV101 = ApiMonthlyCheckinAnswerV101;
class ApiDailyCheckinAnswer {
    constructor() {
        this.feelingRating = null;
        this.priorities = null;
        this.otherPriority = null;
    }
}
exports.ApiDailyCheckinAnswer = ApiDailyCheckinAnswer;
class ApiOnboardingAnswer {
    constructor() {
        this.gender = null;
        this.age = null;
        this.underMedicalCareLast6Months = null;
        this.zipcode = null;
        this.yearsAtCompany = null;
        this.currentRole = null;
    }
}
exports.ApiOnboardingAnswer = ApiOnboardingAnswer;
exports.RECOMMENDATIONS_VISIT = 'recommendations-visit';
exports.RESOURCES_VISIT = 'resources-visit';
exports.DAILY_JOURNEY_VISIT = 'daily-journey-visit';
exports.CHECKIN_VISIT = 'checkin-visit';
