import './LoginPage.scss';
import * as React from 'react';
import {Logo} from "../../components/Logo/Logo";
import {LoginForm} from "../../components/LoginForm/LoginForm";

export const LoginPage: React.FC = () => {

    return (
        <div className='login-page'>
            <div className='header'>
                <Logo />
            </div>

            <div className='body'>
                <LoginForm />
            </div>
        </div>
    );


};
