import * as React from "react";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    useMediaQuery
} from "@material-ui/core";

interface Props {
    fieldName: string;
    value: string | null;
    handleChange: (name: string, value: string | null) => void;
}


export const GenderOptions: React.FC<Props> = ({fieldName, value, handleChange}: Props) => {

    const handleChangeInternal = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        if(event.target.value ) {
            handleChange(fieldName, event.target.value as string);
        } else {
            handleChange(fieldName, null);
        }
    };

    const isSmallScreen = useMediaQuery('(max-width: 500px)');

    const options = [
        {value: "female", label: "Female"},
        {value: "male", label: "Male"},
        {value: "non-binary", label: "Non-Binary"},
        {value: "other", label: "Other"},
    ];
    if (isSmallScreen) {
        options.unshift({value: "", label: ""})
    }


    return(
        <FormControl variant="outlined" className="gender-options-component field">
            <InputLabel id="gender-options-label">Gender</InputLabel>
            <Select
                labelId="gender-options-label"
                onChange={handleChangeInternal}
                value={value || ""}
                variant="outlined"
                label="Gender"
                native={isSmallScreen}>
                {
                    options.map((opt, index) => {
                        return isSmallScreen ?
                            <option key={index} value={opt.value}>{opt.label}</option> :
                            <MenuItem key={index} value={opt.value}>{opt.label}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    );
};
