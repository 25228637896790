import './SignUpForm.scss';
import * as React from 'react';
import {Button, CircularProgress, TextField, FormControl, Select, MenuItem, InputLabel} from "@material-ui/core";
import {useContext, useEffect, useState} from "react";
import {Company, User} from "journey-shared/journey/ApiTypes";
import {JourneyApiClient} from 'journey-shared/journey/JourneyApiClient';
import { useParams } from "react-router-dom";
import {ErrorMessage} from "./ErrorMessage";
import {extractErrorMessage} from "../../common/utils";
import {useHistory} from "react-router-dom";
import {ApplicationContext, CHECKIN_PATH} from "../Shared/Shared";
import * as Sentry from "@sentry/react";
import {Severity} from "@sentry/react";
import ClientConfig from 'journey-shared/journey/ClientConfig';

export const SignUpForm: React.FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [validationErrorMessage, setValidationErrorMessage] = useState<string | null>(null);
    const [isInvalidUrl, setIsInvalidUrl] = useState<boolean>(false);
    const [company, setCompany] = useState<Company | null>(null);

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [workEmail, setWorkEmail] = useState<string>('');
    const [departmentId, setDepartmentId] = useState<any>('');
    const [tierId, setTierId] = useState<any>('');
    const [locationId, setLocationId] = useState<any>('');

    const {companyCode} = useParams<{companyCode: string}>();

    const history = useHistory();
    const {setCurrentUser} = useContext(ApplicationContext);

    const loadCompany = () => {
        setIsLoading(true);

        if (!companyCode) {
            setIsInvalidUrl(true);
            setErrorMessage('Invalid Url');
            setIsLoading(false);
            Sentry.captureEvent({message: "Invalid Url", level: Severity.Info});
            return;
        }
        JourneyApiClient.getCompany(companyCode)
            .then(company => {
                setCompany(company);
                setIsLoading(false);
            })
            .catch((error: any) => {
                if(error && error.response && error.response.status === 404) {
                    Sentry.captureEvent({message: "Invalid Url", level: Severity.Info});
                    setIsInvalidUrl(true);
                } else {
                    Sentry.captureException(error);
                    setErrorMessage(extractErrorMessage(error));
                }
                setIsLoading(false);
            });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadCompany, []);


    const submitForm = () => {
        setIsLoading(true);
        return JourneyApiClient.registerUser({
            firstName,
            lastName,
            email: workEmail,
            marketingOptIn: true,
            companyCode: company ? company.code : '',
            departmentId: departmentId !== '' ? departmentId : null,
            tierId: tierId !== '' ? tierId : null,
            locationId: locationId !== '' ? locationId : null,
            isDeleted: false,
        }).then( (user: User) => {
            if (user.vimeoAuthToken && user.journeyAuthToken) {
                //user was successfully created and added to Vimeo
                // redirect user to new app
                let newFrontendRoot = ClientConfig.newFrontendRoot || "https://app2.journey.live";
                window.location.replace(newFrontendRoot);
            } else {
                setErrorMessage("Your registration was successful but we were not able to log you in.");
                setIsLoading(false);
            }
        }).catch( error => {
            if(error && error.response && error.response.status === 422) {
                setValidationErrorMessage(extractErrorMessage(error));
            } else {
                setErrorMessage(extractErrorMessage(error));
            }
            Sentry.captureException(error);
            setIsLoading(false);
        });
    };

    const loadingContent =
        <div>
            <h1> Please Wait </h1>
            <div className='loading-content animate__animated animate__fadeIn'>
                <CircularProgress className='loading-spinner' size={100} />
            </div>
        </div>;


    const formContent =
        <form className='body-content animate__animated animate__fadeIn' action='#' onSubmit={submitForm}>

            <h1>Self Registration Form</h1>

            <TextField
                required
                label="First Name"
                variant="outlined"
                className="field"
                value={firstName}
                onChange={ e => setFirstName(e.target.value)}
            />
            <TextField
                required
                label="Last Name"
                variant="outlined"
                className="field"
                value={lastName}
                onChange={ e => setLastName(e.target.value)}
            />
            <TextField
                required
                label="Email"
                variant="outlined"
                className="field"
                value={workEmail}
                onChange={ e => setWorkEmail(e.target.value)}
            />
            <TextField
                required
                disabled={true}
                label="Company"
                variant="outlined"
                className="field"
                value={company ? company.name : ""}
            />
            {company && company.departments?.length ? (
                <FormControl required variant="outlined" className="field">
                    <InputLabel id="select-department-label">Department</InputLabel>
                    <Select
                    labelId="select-department-label"
                    id="select-department"
                    value={departmentId}
                    onChange={ e => setDepartmentId(e.target.value)}
                    label="Department"
                    >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>

                    {company.departments.map((dept) => <MenuItem key={dept.id} value={dept.id}>{dept.name}</MenuItem>)}
                    </Select>
                </FormControl>
            ) : null}
            {company && company.tiers?.length ? (
                <FormControl required variant="outlined" className="field">
                    <InputLabel id="select-tier-label">Tier</InputLabel>
                    <Select
                    labelId="select-tier-label"
                    id="select-tier"
                    value={tierId}
                    onChange={ e => setTierId(e.target.value)}
                    label="Tier"
                    >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>

                    {company.tiers.map((tier) => <MenuItem key={tier.id} value={tier.id}>{tier.name}</MenuItem>)}
                    </Select>
                </FormControl>
            ) : null}
            {company && company.locations?.length ? (
                <FormControl required variant="outlined" className="field">
                    <InputLabel id="select-location-label">Location</InputLabel>
                    <Select
                    labelId="select-location-label"
                    id="select-location"
                    value={locationId}
                    onChange={ e => setLocationId(e.target.value)}
                    label="Location"
                    >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>

                    {company.locations.map((location) => <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>)}
                    </Select>
                </FormControl>
            ) : null}
            <div className="email-notice">
                Check your email inbox for instructions on how to start using Journey LIVE!
            </div>
            <Button type='submit' className='field paper-submit-button'>
                Submit
            </Button>
        </form>;


    const formHasErrors = (errorMessage || isInvalidUrl || validationErrorMessage);

    return (
        <div className='sign-up-form-component paper-container'>
            {
                (isLoading) && loadingContent
            }
            {
                (!isLoading && !formHasErrors && company) && formContent
            }
            {
                (!isLoading && formHasErrors) &&
                <ErrorMessage {...{isInvalidUrl, errorMessage, validationErrorMessage}} />
            }
        </div>
    );

};
