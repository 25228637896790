import './MonthlyCheckinForm.scss';
import * as React from 'react';
import {Button, TextField, useMediaQuery} from "@material-ui/core";
import {useState} from "react";
import {StressLevelOptions} from "./StressLevelOptions";
import {ManagementOptions} from "./ManagementOptions";
import {FrequencyOptions} from "./FrequencyOptions";
import {ApplicationContext, DISCLAIMER} from "../Shared/Shared";
import {JourneyApiClient} from 'journey-shared/journey/JourneyApiClient';
import {FormName} from "journey-shared/journey/ApiTypes";
import {PleaseWait} from "../PleaseWait/PleaseWait";
import {GoalsQuestion} from "../GoalsQuestion/GoalsQuestion";
import * as Sentry from "@sentry/react";
import {useContext} from "react";

interface Props {
    onSubmitSuccessful: (formName: FormName) => void;
    redirectToResources: () => void;
}

export const MonthlyCheckinForm: React.FC<Props> = (props) => {

    const [formValues, setFormValues] = useState({
        stressLevel: null,
        manageStressLevel: null,
        feelingsFrequency: null,
        manageFeelingsLevel: null,
        feelingsFrequencyAffectWork: null,
        daysEmotionallyChallenging: null,
    });

    const {showGlobalError} = useContext(ApplicationContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [otherOptionValue, setOtherOptionValue] = useState<string | null>(null);
    const [isGoalsQuestionValid, setIsGoalsQuestionValid] = useState<boolean>(true);
    const isSmallScreen = useMediaQuery('(max-width:500px)');

    const handleValueChange = (name: string, value: number | null) => {
        setFormValues({...formValues, [name]: value});
    };

    const handleSave = async () => {
        try {
            setIsLoading(true);
            //verify user responses to determine if a redirection to resources is needed
            if(formValues.stressLevel != null && formValues.stressLevel! >= 8 && formValues.manageStressLevel != null && formValues.manageStressLevel! <= 2) {
                props.redirectToResources();
            } else if(formValues.feelingsFrequency === 5 && formValues.manageFeelingsLevel != null && formValues.manageFeelingsLevel! <= 2) {
                props.redirectToResources();
            } else if(formValues.feelingsFrequencyAffectWork === 5) {
                props.redirectToResources();
            } else if(formValues.daysEmotionallyChallenging != null && formValues.daysEmotionallyChallenging! >= 7) {
                props.redirectToResources();
            }

            await JourneyApiClient.saveMonthlyCheckinAnswers({
                stressLevel: formValues.stressLevel,
                manageStressLevel: formValues.manageStressLevel,
                feelingsFrequency: formValues.feelingsFrequency,
                manageFeelingsLevel: formValues.manageFeelingsLevel,
                feelingsFrequencyAffectWork: formValues.feelingsFrequencyAffectWork,
                daysEmotionallyChallenging: formValues.daysEmotionallyChallenging,
                priorities: JSON.stringify(selectedOptions),
                otherPriority: otherOptionValue,
            });
            props.onSubmitSuccessful("monthly");

        } catch (error) {
            setIsLoading(false);
            Sentry.captureException(error);
            showGlobalError("Could not save monthly checkin.");
        }
    };

    if(isLoading) {
        return (
            <div className='monthly-checkin-form-component'>
                <PleaseWait />
            </div>
        );

    } else {
        return (
            <div className='monthly-checkin-form-component paper-container'>
                <h1> Monthly Assessment </h1>

                <div className="title-caption">
                    {DISCLAIMER}
                </div>

                <div className="question-note">
                    The following questions relate to the last 30 days.
                </div>

                <div className="long-text">
                    <div>
                        During the last month, how would you rate your stress?
                    </div>

                    <StressLevelOptions
                        fieldName="stressLevel"
                        handleChange={handleValueChange}
                        value={formValues.stressLevel}
                    />
                </div>

                <div className="long-text">
                    <div>
                        How would you rate your ability to <strong>manage</strong> your stress?
                    </div>

                    <ManagementOptions
                        fieldName="manageStressLevel"
                        onChange={handleValueChange}
                        value={formValues.manageStressLevel}/>
                </div>

                <div className="long-text">
                    <div>
                        How often did you feel sad, anxious, worried, or irritable?
                    </div>

                    <FrequencyOptions
                        fieldName="feelingsFrequency"
                        handleChange={handleValueChange}
                        value={formValues.feelingsFrequency}/>
                </div>

                <div className="long-text">
                    <div>
                        How would you rate your ability to <strong>manage</strong> those
                        feelings (sad, anxious, worried, or irritable)?
                    </div>

                    <ManagementOptions
                        fieldName="manageFeelingsLevel"
                        onChange={handleValueChange}
                        value={formValues.manageFeelingsLevel}/>
                </div>

                <div className="long-text">
                    <div>
                        How often did your stress or feelings get in the way of work?
                    </div>
                    <FrequencyOptions
                        fieldName="feelingsFrequencyAffectWork"
                        handleChange={handleValueChange}
                        value={formValues.feelingsFrequencyAffectWork}/>
                </div>

                <div className="long-text">
                    <div>
                        How many days were emotionally challenging (you didn’t feel like yourself, low energy, etc.)?
                    </div>
                    <TextField
                        name="daysEmotionallyChallenging"
                        className="field"
                        variant="outlined"
                        type="number"
                        helperText="Number between 0 - 30"
                        error={false}
                        onChange={event => handleValueChange(event.target.name, parseInt(event.target.value))}
                    />
                </div>

                <div className="long-text">
                    What would you like to work on or improve over the next 30 days?  Pick your top 3.

                    <GoalsQuestion
                        otherOptionValue={otherOptionValue}
                        setOtherOptionValue={setOtherOptionValue}
                        onSelectedValueChanged={setSelectedOptions}
                        setValid={setIsGoalsQuestionValid}
                    />
                </div>


                <Button type='submit' className='field paper-submit-button'
                        onClick={handleSave} disabled={!isGoalsQuestionValid}>
                    Submit
                </Button>

                {
                    isSmallScreen &&
                    <Button className='field skip-button' onClick={handleSave}>
                        Skip
                    </Button>
                }

            </div>
        );
    }
};
