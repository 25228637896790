import "./NoneMessage.scss";
import * as React from 'react';
import {useContext, useEffect} from "react";
import {LinearProgress} from "@material-ui/core";
import {ApplicationContext, SuccessIcon} from "../Shared/Shared";


export const NoneMessage: React.FC = () => {

    const {currentUser} = useContext(ApplicationContext);

    useEffect(() => {
        if(currentUser) {
            let vimeoUrl = `https://portal.journey.live/browse?ticket=${currentUser.vimeoAuthToken}`;
            setTimeout(() => {
                window.location.replace(vimeoUrl);
            }, 2000);
        }
    }, [currentUser]);

    return(
        <div className="none-message-component paper-container">

            <h1>You are all set!</h1>

            <SuccessIcon />

            <div className='content animate__animated animate__fadeIn'>
                We are redirecting you to Journey LIVE.
            </div>

            <LinearProgress className='progress animate__animated animate__fadeIn' />
        </div>
    )

};
