import * as React from 'react';
import {GetCompanyBenefitsInfoResponse} from "journey-shared/journey/ApiTypes";
import "./Resources.scss";
import Grid from "@material-ui/core/Grid";
import Image1 from "../../assets/resources_page/image1.png";
import {Benefit} from "../Benefit/Benefit";
import Image2 from "../../assets/resources_page/image2.jpeg";
import Image3 from "../../assets/resources_page/image3.png";
import HeroImage from "../../assets/resources_page/resources-hero.jpg";

interface Props {
    benefitsInfo: GetCompanyBenefitsInfoResponse;
}


export const Resources: React.FC<Props> = ({benefitsInfo}) => {

    const {company, companyBenefits, standardBenefits} = benefitsInfo;

    const getLogo = () => {
        if (company && company.logoUrl) {
            return <img id="companyLogo" src={company.logoUrl} alt="company logo"/>
        } else {
            return null;
        }
    }

    return(
        <div className='resources-component'>

            <img id='heroImage' src={HeroImage} alt="hero"/>

            <Grid container className='info-grid max-width-900'>
                <Grid item sm={8} xs={12} className='column'>
                    <div className="welcome-header">
                        <h1>Welcome</h1>
                        { getLogo() }
                    </div>
                    <div className='welcome-text'>
                        Below you will find a list of third party resources that your employer provides as a benefit to you!
                        These resources are aimed to improve your overall wellbeing
                    </div>
                </Grid>
                <Grid item sm={4} xs={12} id='image1column' className='column'>
                    <img id='image1' src={Image1} alt='Journey instructors' />
                </Grid>
            </Grid>
            <div id='benefits-section' className='journey-gradient-bg'>
                {
                    !!companyBenefits.length &&
                    <div id='third-party-benefits-section' className='max-width-900'>
                        <h1>Resources for You</h1>
                        <p className='text-center'>
                            Resources provided to you by {company.name} as part of your employee benefits package.
                        </p>
                        <div className='benefits-list'>
                            {
                                companyBenefits.map((benefit, index) => <Benefit key={index} benefit={benefit} />)
                            }
                        </div>

                        <img id='image2' src={Image2} alt='Man focused' />

                        <div className='divider' />
                    </div>
                }
                {
                    standardBenefits.length &&
                    <div id='standard-benefits-section'>

                        <h1>Resources for All</h1>
                        <p className='text-center max-width-900'>
                            Regardless of your organization, these resources are open to all who need them.
                        </p>

                        <div id='benefits-grid'>

                            <div  className='column left'>
                                <img id='image3' src={Image3} alt='Couple in the mountains' />
                            </div>

                            <div className='column right benefits-list'>
                                {
                                    standardBenefits.map((benefit, index) => <Benefit key={index} benefit={benefit} />)
                                }
                            </div>

                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
