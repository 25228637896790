import "./Benefit.scss";
import * as React from 'react';
import {Benefit as ApiBenefit} from "journey-shared/journey/ApiTypes";

interface Props {
    benefit: ApiBenefit;
}

export const Benefit: React.FC<Props> = ({benefit}) => {



    return(
        <div className='benefit-component'>
            {
                benefit.redirectUrl ?
                    <a target="_blank" href={benefit.redirectUrl || ""} rel="noreferrer">
                        <img src={benefit.imageUrl || ""} alt="benefit"/>
                    </a>
                    :
                    <img src={benefit.imageUrl || ""} alt="benefit"/>
            }
            <h1>{benefit.name}</h1>
            <p>{benefit.description}</p>
        </div>
    );
};
