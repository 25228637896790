import './DailyJourneyPage.scss';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {ApplicationContext} from "../../components/Shared/Shared";
import {useHistory, useLocation} from "react-router-dom";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApiDailyJourney, DAILY_JOURNEY_VISIT} from "journey-shared/journey/ApiTypes";
import * as Sentry from "@sentry/react";
import {extractErrorMessage} from "../../common/utils";
import {NavigationBar} from "../../components/NavigationBar/NavigationBar";
import {DailyJourney} from "../../components/DailyJourney/DailyJourney";

export const DailyJourneyPage: React.FC = () => {

    const {currentUser, showGlobalError, isFetchingCurrentUser, showGlobalWarning} = useContext(ApplicationContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dailyJourneyInfo, setDailyJourneyInfo] = useState<ApiDailyJourney | null>(null);
    const history = useHistory();
    let id = new URLSearchParams(useLocation().search).get("id");

    useEffect(() => {
        if(id) {
            //id is specified as query parameter, display daily journey with id specified
            JourneyApiClient.getDailyJourneyById(id)
            .then(setDailyJourneyInfo)
            .catch((error) => {
                Sentry.captureException(error);
                showGlobalError(extractErrorMessage(error));
            })
            .finally(() => setIsLoading(false));
        }
        else {
            //id not specified, display current day daily journey
            JourneyApiClient.getDailyJourneyInfo()
            .then(setDailyJourneyInfo)
            .then(() => JourneyApiClient.createUserEngagementEvent(DAILY_JOURNEY_VISIT))
            .catch((error) => {
                if (error.response?.status === 401) {
                    showGlobalWarning("Please Sign In");
                } else {
                    Sentry.captureException(error);
                    showGlobalError(extractErrorMessage(error));
                }
            })
            .finally(() => setIsLoading(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='daily-journey-page'>

            {!id && (<NavigationBar />)}
            
            {
                (function(){
                    if(!isFetchingCurrentUser && !currentUser) {
                        setTimeout(() => history.push("/login"), 1000);
                        return <PleaseWait />;
                    } else if (isLoading) {
                        return <PleaseWait />;
                    } else if (!isLoading && dailyJourneyInfo) {
                        return <DailyJourney dailyJourney={dailyJourneyInfo} />;
                    } else {
                        return <div>Something went wrong.</div>;
                    }
                })()
            }
        </div>
    );

};
