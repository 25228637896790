import * as React from "react";
import {MenuItem, Select, useMediaQuery} from "@material-ui/core";

interface Props {
    fieldName: string;
    value: number | null;
    onChange: (name: string, value: number | null) => void;
}


export const ManagementOptions: React.FC<Props> = ({fieldName, value, onChange}: Props) => {

    const handleChangeInternal = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        if(event.target.value) {
            onChange(fieldName, event.target.value as number);
        } else {
            onChange(fieldName, null);
        }
    };

    const isSmallScreen = useMediaQuery('(max-width:500px)');

    const options = [
        {value: 1, label: "1 - Very Poor"},
        {value: 2, label: "2"},
        {value: 3, label: "3"},
        {value: 4, label: "4"},
        {value: 5, label: "5 - Medium"},
        {value: 6, label: "6"},
        {value: 7, label: "7"},
        {value: 8, label: "8"},
        {value: 9, label: "9"},
        {value: 10, label: "10 - High"},
    ];

    if (isSmallScreen) {
        options.unshift({value: 0, label: ""})
    }

    return(
        <Select
            value={ value || "" }
            onChange={handleChangeInternal}
            className="field"
            variant="outlined"
            native={isSmallScreen} >
            {
                options.map((opt, index) => {
                    return isSmallScreen ?
                        <option key={index} value={opt.value}>{opt.label}</option> :
                        <MenuItem key={index} value={opt.value}>{opt.label}</MenuItem>
                })
            }
        </Select>
    );
};
